import { computed } from "#imports";
import { COMPONENT_VISIBILITY_TYPES } from "~/utils/constants.js";

export function useUser() {
  const config = useRuntimeConfig();

  const profilePictureCookie = useCookie("profilePicture", {
    domain: config.public.COOKIES_DOMAIN,
  });

  const user = useState("user", () => null);

  const isLogged = computed(() => user.value?.email?.length > 0);
  const fullName = computed(() => {
    const result = [];
    if (user.value?.firstname) result.push(user.value.firstname);
    if (user.value?.lastname) result.push(user.value.lastname);
    return result.join(" ");
  });

  const profilePicture = computed(() => {
    if (user.value?.profile_picture) return user.value.profile_picture;
    if (profilePictureCookie.value) return profilePictureCookie.value;
    return null;
  });

  const isValidLoyaltyProgram = computed(() => {
    return user?.value?.loyalty_program?.card?.is_valid;
  });

  const showComponentByState = (state) => {
    if (state === COMPONENT_VISIBILITY_TYPES.all) return true;

    if (
      state === COMPONENT_VISIBILITY_TYPES.membersCsc &&
      isValidLoyaltyProgram.value
    )
      return true;

    if (
      state === COMPONENT_VISIBILITY_TYPES.logged &&
      isLogged.value &&
      !isValidLoyaltyProgram.value
    )
      return true;

    return state === COMPONENT_VISIBILITY_TYPES.unLogged && !isLogged.value;
  };

  return {
    user,
    isLogged,
    isCscMember: isValidLoyaltyProgram,
    fullName,
    profilePicture,
    showComponentByState,
  };
}
